<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-search">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="手机号码">
              <el-input placeholder="请输入手机号码" clearable v-model="searchForm.phone" :maxLength="11"/>
            </el-form-item>
            <el-form-item label="支付状态">
              <el-select v-model="searchForm.status"
                         clearable>
                <el-option v-for="item in typeList"
                           :key="item.id"
                           :label="item.text"
                           :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="下单时间">
              <el-date-picker
                  v-model="searchForm.timeValue"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
              @change="onSearchDateChange">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ApeTable ref="apeTable" :data="userList" :columns="columns" :loading="loadingStatus"
                  :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
              slot="first-column"
              width="80"
              align="center"
              label="Drag">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="拖动排序" placement="top-start">
                <span class="drag-handle" :data-id="scope.row.id"><i class="el-icon-rank"></i></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType=='icon'"
              label="操作"
              width="60"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>
                <el-tooltip effect="dark" content="查看" placement="top-start"
                            v-if="userPermissions.indexOf('order_view') != -1">
                  <el-button size="mini" icon="el-icon-edit" @click="viewButton(scope.row.order_no)"></el-button>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType=='text'"
              label="操作"
              width="60"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>
                  <el-button size="mini" v-if="userPermissions.indexOf('order_view') != -1"
                             @click="viewButton(scope.row.order_no)">查看</el-button>
              </span>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>
    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-form :model="formData" label-position="right" label-width="120px">
          <el-form-item label="用户昵称：">
            <label>{{ formData.nick_name }}</label>
          </el-form-item>
          <el-form-item label="订单编号：">
            <label>{{ formData.order_no }}</label>
          </el-form-item>
          <el-form-item label="用户ID：">
            <label>{{ formData.user_id }}</label>
          </el-form-item>
          <el-form-item label="手机号码：">
            <label>{{ formData.phone }}</label>
          </el-form-item>
          <el-form-item label="支付金额(元)：">
            <label>{{ formData.pay_money }}</label>
          </el-form-item>
          <el-form-item label="支付方式：">
            <label>{{ formData.pay_way_alias }}</label>
          </el-form-item>
          <el-form-item label="购买套餐：">
            <label>{{ formData.package_item_name }}</label>
          </el-form-item>
          <el-form-item label="状态：">
            <label>{{ formData.status_alias }}</label>
          </el-form-item>
          <el-form-item label="创建时间：">
            <label>{{ formData.created_at }}</label>
          </el-form-item>
        </el-form>
      </template>
    </ApeDrawer>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";

import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import Sortable from 'sortablejs'
import {mapGetters} from 'vuex'

export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
  },
  data() {
    return {
      loadingStatus: true,
      columns: [
        {
          title: '用户昵称',
          value: 'nick_name',
        },
        {
          title: '手机号',
          value: 'phone',
        },
        {
          title: '购买套餐',
          value: 'package_item_name',
        },
        {
          title: '支付方式',
          value: 'pay_way_alias',
        },
        {
          title: '支付金额(元)',
          value: 'pay_money',
        },
        {
          title: '状态',
          value: 'status',
          value_display: 'status_alias',
          style_plan: [
            {
              value: "finish",
              style: "color:#67C23A;"
            },
            {
              value: "ready_pay",
              style: "color:#F56C6C;"
            }
          ]
        },

        {
          title: '下单时间',
          value: 'created_at'
        },
      ],
      // 表格列表数据
      userList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0, // 分页的offset,序号列使用
      },
      // 表单结构
      formData: {},
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '560px',
      },
      typeList: [],
      //搜索表单
      searchForm: {},
      dateForm: {
        isShowData: true,
        label: '下单时间'
      },
      isShowSelect: true,
      selectForm: {
        label: '支付状态',
        placeholder: '请选择支付状态',
        typeList: []
      },
      searchParams: {},
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  mounted() {
    this.$nextTick(() => {
      this.iniUserList();
      this.dragSort();
      this.getOrderStatus();
    })
  },
  methods: {
    // 切换页码操作
    async switchPaging() {
      this.iniUserList(NO_DEL, this.getSearchInfo())
    },

    // 响应查看按钮
    async viewButton(order_no) {
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '订单详情(订单编号：' + order_no + ')';
      this.typeList = [];
      let {info} = await this.$api.getOrderInfo({order_no});
      this.formData = info;
      this.drawerData.loading = false
    },
    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false;
      this.drawerData.loading = true
    },
    // 处理抽屉确认
    async drawerConfirm() {
      this.drawerData.visible = false;
      this.drawerData.loading = true
    },

    // 初始用户列表
    async iniUserList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);

      for (let param in params) {
        if (params[param] == "") {
          continue;
        }
        inputData[param] = params[param];
      }

      let {list, pages} = await this.$api.getOrderList(inputData);
      this.userList = [];
      this.$nextTick(() => {
        this.userList = list
      });
      this.pagingData.total = pages.total;
      this.pagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    // 拖拽排序数据提交，请求接口
    async dragSortSubmit() {
      this.$message.success('保存成功!')
    },
    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
      this.sortable = Sortable.create(el, {
        handle: ".drag-handle",
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: () => {
          let Ids = [];
          let tmp = el.querySelectorAll('.drag-handle');
          for (let i = 0, len = tmp.length; i < len; i++) {
            Ids.push(tmp[i].dataset.id)
          }
          this.dragSortSubmit(Ids)
        },
      })
    },

    //搜索
    onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      // if (this.searchForm.timeValue && this.searchForm.timeValue.length > 0) {
      //   let timeVal = this.searchForm.timeValue
      //   let startTime = timeVal[0]
      //   let endTime = timeVal[1]
      //   this.$set(this.searchForm, "start_time", startTime)
      //   this.$set(this.searchForm, "end_time", endTime)
      // }
      // let params = {}
      // params.start_time = this.searchForm.start_time
      // params.end_time = this.searchForm.end_time
      // params.phone = this.searchForm.phone
      // params.status = this.searchForm.status

      let params = this.getSearchInfo()
      this.iniUserList(DEL, params);
    },

    getSearchInfo() {
      if (JSON.stringify(this.searchForm) === '{}') {
        return {}
      }

      if (this.searchForm.timeValue && this.searchForm.timeValue.length > 0) {
        let timeVal = this.searchForm.timeValue
        let startTime = timeVal[0]
        let endTime = timeVal[1]
        this.$set(this.searchForm, "start_time", startTime)
        this.$set(this.searchForm, "end_time", endTime)
      }
      let params = {}
      params.start_time = this.searchForm.start_time
      params.end_time = this.searchForm.end_time
      params.phone = this.searchForm.phone
      params.status = this.searchForm.status
      return params
    },

    //重置搜索
    onResetSearch() {
      this.searchForm = {};
      this.iniUserList();
    },

    //获取订单状态列表
    async getOrderStatus() {
      let data = await this.$api.commonDict('order_status');
      if (data && data.length > 0) {
        this.selectForm.typeList = data;
        this.typeList = data;
      }
    },

    onSearchDateChange(e) {
      if (!e) {
        this.$set(this.searchForm, "start_time", '')
        this.$set(this.searchForm, "end_time", '')
      }
    }
  },


}
</script>

<style lang="stylus" scoped>
.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0px
  margin-right 12px

.el-select > .el-input
  width 373.33px

.el-radio.is-bordered
  width 100px

.el-color-picker
  position absolute
</style>
